// iframe 外层引用的 sdk，用于透传请求
// @AUTHOR CAIHUAZHI <huazhi.chz@alibaba-inc.com>
// @CREATE 2023/11/27 14:39

import { logger } from '@alife/saber-simplify';
import { IFRAME_CLASS_NAME } from '../common/config';
import { encodeMessage, decodeMessage, truncateString } from '../common/utils';
import { request } from '../common/service';
import { ActionType, IFetchMessagePayload, IMessageVO } from '../common/types';

class MainSDK {
  static displayName = 'MainSDK';

  apiMap: Record<string, string | (() => Promise<any>)> = {};
  dataType: 'json' | 'formData';
  requestOptions: Record<string, any> = {};
  secret: string;

  constructor() {
    this.bindMessageListener();

    const initConfig = (window as any).INTL_OP_FRAME_SDK_CONFIG || {};
    this.apiMap = initConfig.apiMap || this.apiMap;
    this.dataType = initConfig.dataType || 'json';
    this.requestOptions = initConfig.requestOptions || {};
    this.secret = initConfig.secret || '';
    (window as any).__openFrameSDKInited = true;
  }

  private async handleFetchMessage(sequenceId: string, payload: IFetchMessagePayload) {
    logger.debug('[MainSDK] handleFetchMessage', sequenceId, payload);

    const { api, options = {}} = payload || {};
    const fetchApi = this.apiMap[api] || api;

    try {
      if (typeof fetchApi === 'string') {
        const response = await request(fetchApi, {
          ...options,
          dataType: this.dataType,
          ...this.requestOptions,
        });
        if (this.secret) response.secret = this.secret;
        this.sendMessage({
          sequenceId,
          type: ActionType.FETCH,
          payload: response,
        });
      } else {
        const response = await fetchApi();
        if (this.secret) response.secret = this.secret;
        this.sendMessage({
          sequenceId,
          type: ActionType.FETCH,
          payload: response,
        });
      }
    } catch (ex) {
      this.sendMessage({
        sequenceId,
        type: ActionType.FETCH,
        payload: {},
        error: ex,
      });
    }
  }

  private sendMessage(message: IMessageVO) {
    const frames = document.querySelectorAll<HTMLIFrameElement>('iframe');
    if (!frames.length) {
      return logger.error('[MainSDK] frame not found, query selector: ', `.${IFRAME_CLASS_NAME}`);
    } else {
      const msg = encodeMessage('MainSDK', message);
      frames.forEach((f) => {
        if (f.className === IFRAME_CLASS_NAME) {
          const { origin } = new URL(f.getAttribute('src') || '');
          f.contentWindow?.postMessage(msg, origin);
        } else {
          const childrenFrames = f.contentDocument?.querySelectorAll<HTMLIFrameElement>('iframe');
          if (childrenFrames && childrenFrames.length > 0) {
            childrenFrames.forEach((f2) => {
              if (f2.className === IFRAME_CLASS_NAME) {
                const { origin } = new URL(f2.getAttribute('src') || '');
                f2.contentWindow?.postMessage(msg, origin);
              }
            });
          }
        }
      });
    }
  }

  private bindMessageListener() {
    window.addEventListener('message', (e) => {
      logger.debug(`[MainSDK] receive message from ${e.origin}`, truncateString(e.data));
      // 只接收来自内层的消息，防止处理自己的消息
      const message = decodeMessage<IFetchMessagePayload>('FrameSDK', e.data);
      if (!message) return;
      const { sequenceId, type, payload } = message;
      if (type === ActionType.FETCH) {
        this.handleFetchMessage(sequenceId, payload);
      }
    });
  }
}

function initMainSdk() {
  // 初始化页面组件
  (window as any).__openFrameSDK = new MainSDK();
}

function main() {
  // 判断当前页面状态，来决定要执行的操作
  (window as any).__openFrameSDKVersion = 4;
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    // 已经加载完成
    initMainSdk();
  } else {
    document.addEventListener('DOMContentLoaded', initMainSdk);
  }
}

// isv页面引入sdk资源后的执行入口
main();
